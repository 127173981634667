    const projectId = "0b0491c45c973010bc283906f23c9061"

    import { createConfig, waitForTransactionReceipt, signMessage, switchChain, readContract, writeContract, fetchEnsAddress, reconnect, disconnect, getAccount, watchAccount, fetchEnsName } from "@wagmi/core"
    import { mainnet, optimism, base, zora, arbitrum } from "@wagmi/core/chains"

    import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

    import { formatEther, parseUnits, concat, stringToBytes, toHex, hexToBytes, encodeAbiParameters, parseAbiParameters, decodeFunctionResult, isAddress, InsufficientFundsError } from 'viem'


    // pgn not included in viem/wagmi
    export const pgn = {
        id: 424,
        name: 'PGN',
        network: 'pgn',
        nativeCurrency: {
        decimals: 18,
        name: 'PGN',
        symbol: 'ETH'
        },
        rpcUrls: {
            public: { http: ['https://rpc.publicgoods.network'] },
            default: { http: ['https://rpc.publicgoods.network'] }
        },
        blockExplorers: {
            blockscout: { name: 'Blockscout', url: 'https://explorer.publicgoods.network' },
            default: { name: 'Blockscout', url: 'https://explorer.publicgoods.network' }
        },
        contracts: {
        }
    } 

    export const blast = {
        id: 81457,
        name: 'Blast',
        network: 'blast',
        nativeCurrency: {
        decimals: 18,
        name: 'Blast',
        symbol: 'ETH'
        },
        rpcUrls: {
            public: { http: ['https://rpc.blast.io'] },
            default: { http: ['https://rpc.blast.io'] }
        },
        blockExplorers: {
            blockscout: { name: 'Blastscan', url: 'https://blastscan.io/' },
            default: { name: 'Blastscan', url: 'https://blastscan.io/' }
        },
        contracts: {
        }
    } 


    // 1. Define chains
    const chains = [mainnet, optimism, base, pgn, zora, arbitrum, blast];

    const wagmiConfig = defaultWagmiConfig({
      chains,
      projectId,
      metadata: {
        name: 'Token Chat',
        description: 'the name kinda gives it away',
        url: 'https://tokenchat.co',
        icons: ['https://tokenchat.co/static/favicon.png']
      }
    })
    reconnect(wagmiConfig)

    const modal = createWeb3Modal({ 
        wagmiConfig, 
        projectId, 
        chains, 
        themeMode: 'light',
        themeVariables: {
            '--w3m-font-family': 'pure, sans-serif',
            '--w3m-accent-mix': '#000000'
        } 
    })
    window.web3modal = modal

    // // window.web3modal = modal
    // window.ethereumClient = ethereumClient // lol

    const connectButton = document.getElementById("connect-button");
    const disconnectButton = document.getElementById("disconnect-button");
    const menuButton = document.getElementById("menu-button");
    // const signButton = document.getElementById("sign-button");

    // const unwatch_modal = web3modal.subscribeModal(async (e) => { 
    web3modal.subscribeState(async (e) => { 

        let account = getAccount(wagmiConfig)
            
        // user was not connected and cancelled the WC modal
        if(e.open==false 
            && account.isConnected == false
            && $('.mint-form textarea').hasClass("active") ) {
            $('.mint-form textarea').removeClass("active")
        }

    });

    const unwatch = watchAccount(wagmiConfig, { 

        async onChange(account) {

            console.log("eth client updated: ", account);

            if(account.status=="connecting") { return; } // ignore these transient states

            if(account.isConnected && (!current_address || account.adddress!=current_address)) { 

                var ens_name;
                ens_name = await fetchEnsName(wagmiConfig, {address: account.address, chainId: 1})

                current_address = account.address.toLowerCase();
                if(ens_name) {

                    current_ens = ens_name

                    connectButton.innerHTML = ens_name.replace(/\.(eth|xyz|art)$/, '')
                    connectButton.href = "/" + ens_name
                
                } else {
                    
                    connectButton.innerHTML = account.address.replace(/^(......).+/,'$1');
                    connectButton.href = "/" + account.address.toLowerCase();    

                }

                $(disconnectButton).show();

                await $.ajax({
                  type: "POST",
                  url: '/api/session-update',
                  data: JSON.stringify({ session: get_session(), 'address': current_address.toLowerCase(), 'ens_name': ens_name }),
                  dataType: 'json'
                }); // the page-reload wont work since the server won't know we connected

                if(token_websocket_conn) {

                    var msg = {
                        'cmd': 'update',
                        'wallet_address': current_address
                    }
                    token_websocket_conn.send(JSON.stringify(msg));

                }

                apply_wallet_page_changes();

                if(page_data.state.wallet_connection_required===true) {
                    show_loading();
                    window.setTimeout(function () { update_page_contents(); }, 500); // refresh
                }

            } else if(!account.isConnected ) {

                connectButton.innerHTML = "Connect";

                current_address = null;
                current_ens = null;

                $(disconnectButton).hide();

                await $.ajax({
                  type: "POST",
                  url: '/api/session-update',
                  data: JSON.stringify({ session: get_session(), 'address': null, 'ens_name': null }),
                  dataType: 'json'
                });

                apply_wallet_page_changes();

                if(page_data.state.wallet_connection_required===true) {
                    show_loading();
                    window.setTimeout(function () { update_page_contents(); }, 500); // refresh
                }

            }

        }

    })

    connectButton.addEventListener("click", async function () {
        let account = getAccount(wagmiConfig)
        if(! account.isConnected) { web3modal.open() }

        return false; // otherwise, we just fire the normal href event and open your profile, easy
    });

    disconnectButton.addEventListener("click", async function () {
        $('#menu-items').toggle();
        await disconnect(wagmiConfig)
    });

    menuButton.addEventListener("click", async function () {
        $('#menu-items').toggle();
    });

    // async function onSignclick() {

    //     try {

    //         // ops: hide_collection, hide_user, ban_user 

    //         var res = await signMessage({ message: JSON.stringify({"op": "hide_collection", "arg": "0xABCD", "ts": + get_unix_time()}) })
    //         console.log(res)

    //     } catch (e) {

    //         if(e.name == "ConnectorNotFoundError") {
    //             console.log("Wallet not connected")
    //         } else if (e.name == "UserRejectedRequestError") { 
    //             console.log("User declined")
    //         } else {
    //             console.log(e)
    //         }

    //     }

    // }


    // async function requestSignMessage() { // YESSSS

    //     try {

    //         // ops: hide_collection, hide_user, ban_user 

    //         var res = await signMessage({ message: JSON.stringify({"op": "hide_collection", "arg": "0xABCD", "ts": + get_unix_time()}) })
    //         console.log(res)

    //     } catch (e) {

    //         if(e.name == "ConnectorNotFoundError") {
    //             console.log("Wallet not connected")
    //         } else if (e.name == "UserRejectedRequestError") { 
    //             console.log("User declined")
    //         } else {
    //             console.log(e)
    //         }

    //     }

    // }

    // signButton.addEventListener("click", onSignclick);



    // get the general ABI

    // 721
    // v=12, for ex: https://etherscan.io/address/0x4761f9223765cb4da31c6674d76ff92b95258ef6#writeProxyContract
    // purchaseWithComment

    // 1155 
    // proxy example: https://etherscan.io/address/0x17100300a29deac9c17282ff4725323d38a085b8#readProxyContract
    // implementation: https://etherscan.io/address/0x4c2b5f3b7eadadd58d5ec457abf57f4718b171ae#code
    // v=1.3.0 
    // mint
    // mintWithRewards: https://explorer.zora.energy/address/0x8Ca5e648C5dFEfcdDa06d627F4b490B719ccFD98

    let abi721_norewards = [{
        "inputs": [
            {"internalType": "uint256", "name": "quantity", "type": "uint256" },
            {"internalType": "string", "name": "comment", "type": "string" }
        ],
        "name": "purchaseWithComment",
        "outputs": [
            {"internalType": "uint256", "name": "", "type": "uint256" }
        ],
        "stateMutability": "payable",
        "type": "function"
    }];

    let abi1155_norewards = [{
        "inputs":
        [
            {"internalType": "contract IMinter1155", "name": "minter", "type": "address" },
            {"internalType": "uint256", "name": "tokenId", "type": "uint256" },
            {"internalType": "uint256", "name": "quantity", "type": "uint256" },
            {"internalType": "bytes", "name": "minterArguments", "type": "bytes" }
        ],
        "name": "mint",
        "outputs":
        [],
        "stateMutability": "payable",
        "type": "function"
    }];

    let abi721_rewards = [{
        "inputs": [
            {"internalType": "address", "name": "recipient", "type": "address" },
            {"internalType": "uint256", "name": "quantity", "type": "uint256" },
            {"internalType": "string", "name": "comment", "type": "string" },
            {"internalType": "address", "name": "mintReferral", "type": "address" }
        ],
        "name": "mintWithRewards",
        "outputs": [
            {"internalType": "uint256", "name": "", "type": "uint256" }
        ],
        "stateMutability": "payable",
        "type": "function"
    }];

    let abi_erc20_mint = [{
        "inputs": [
            {"internalType": "address", "name": "mintTo", "type": "address" },
            {"internalType": "uint256", "name": "quantity", "type": "uint256" },
            {"internalType": "address", "name": "tokenAddress", "type": "address" },
            {"internalType": "uint256", "name": "tokenId", "type": "uint256" },
            {"internalType": "uint256", "name": "totalValue", "type": "uint256" },
            {"internalType": "address", "name": "currency", "type": "address" },
            {"internalType": "address", "name": "mintReferral", "type": "address" },
            {"internalType": "string", "name": "comment", "type": "string" }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }];

    let abi1155_rewards = [{
        "inputs": [
            {"internalType": "contract IMinter1155", "name": "minter", "type": "address" },
            {"internalType": "uint256", "name": "tokenId", "type": "uint256" },
            {"internalType": "uint256", "name": "quantity", "type": "uint256" },
            {"internalType": "bytes", "name": "minterArguments", "type": "bytes" },
            {"internalType": "address", "name": "mintReferral", "type": "address" }
        ],
        "name": "mintWithRewards",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }];
    
    let abi_erc20 = [
    {
        "inputs": [
            {"internalType": "address", "name": "owner", "type": "address" },
            {"internalType": "address", "name": "spender", "type": "address" }
        ],
        "name": "allowance",
        "outputs": [
            {"internalType": "uint256", "name": "", "type": "uint256" }
        ],
        "stateMutability": "view",
        "type": "function"
    }, 
    {
        "inputs": [
            {"internalType": "address", "name": "spender", "type": "address" },
            {"internalType": "uint256", "name": "value", "type": "uint256" }
        ],
        "name": "approve",
        "outputs": [
            {"internalType": "bool", "name": "", "type": "bool" }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "account", "type": "address" }
        ],
        "name": "balanceOf",
        "outputs": [
            {"internalType": "uint256", "name": "", "type": "uint256" }
        ],
        "stateMutability": "view",
        "type": "function"
    }];





    window.mint_comment_click = async function (e) {

        let elt = e.target

        if($(elt).hasClass("active")) { return false; }

        $(elt).addClass("active");

        let mint_form = elt.parentNode.parentNode

        let account = getAccount(wagmiConfig)
        if(account.isConnected == false) {
            web3modal.open()
        }

        let edition_id;
        edition_id = $(mint_form).attr('data-collection-chain') + ":" + $(mint_form).attr('data-collection-address');
        if($(mint_form).attr('data-token-id')) {
            edition_id += "/" + $(mint_form).attr('data-token-id')
        }

        let zora_url;
        if($(mint_form).attr('data-collection-chain')=="zsep") {
            zora_url = "https://testnet.zora.co/collect/" + edition_id + "?referrer=" + TOKENCHAT_REWARDS_ADDRESS
        } else {
            zora_url = "https://zora.co/collect/" + edition_id + "?referrer=" + TOKENCHAT_REWARDS_ADDRESS
        }


        // get sales config from
        // 1155: https://zora.co/api/personalize/collection/zora:0x60e31d14078f51290f94d610f7154764067a0cb8/5/sales
        // 721: https://testnet.zora.co/api/personalize/collection/zgor:0x9605837aa1a6dacb972ea300a9e6ef0baf302408/sales
        let sales_status_url;
        let sales_status;
        sales_status_url = "/api/mint-status-v2/" + edition_id

        $(mint_form).find('.mint-total .desc').text("Loading mint info...")

        sales_status = await $.getJSON(sales_status_url)
        if(is_dev()) { console.log(sales_status) }

        // yay active, let's display pricing and fees info
        if(sales_status.sale_open===true 
            && sales_status.sold_out===false 
            /*&& typeof(sales_status.salesStrategy.currency)==="undefined"*/) {

            $(mint_form).attr('data-price-per-token', sales_status.salesStrategy.pricePerToken)
            if(sales_status.currency) {
                $(mint_form).attr('data-collection-currency', sales_status.currency)
                $(mint_form).attr('data-collection-currency-name', sales_status.currency_name)
            
                let erc20_balance = await readContract(wagmiConfig, {
                  address: sales_status.currency,
                  abi: abi_erc20,
                  functionName: 'balanceOf',
                  args: [ current_address ],
                  chainId: CHAIN_IDS[$(mint_form).attr('data-collection-chain')]
                })

                console.log("erc20 balance: " + formatEther(erc20_balance) + " " + sales_status.currency_name);
                // $(mint_form).find('.wallet-info').show();
                $(mint_form).find('.wallet-info .desc').text("Your balance")
                $(mint_form).find('.wallet-info .amount').text( Math.round(parseFloat(formatEther(erc20_balance))) + " $" + sales_status.currency_name.toUpperCase())

            } /*else {
                $(mint_form).find('.wallet-info').hide();
            }*/

            if($(mint_form).attr('data-rewards-recipient-address')
                && $(mint_form).attr('data-rewards-recipient-address') != ""
                && $(mint_form).attr('data-rewards-recipient-address') != current_address) {

                var commenter_name;
                if($(mint_form).attr('data-rewards-recipient-name')) {
                    commenter_name = $(mint_form).attr('data-rewards-recipient-name');
                } else {
                    commenter_name = $(mint_form).attr('data-rewards-recipient-address').replace(/^(......).+/,'$1');
                }
                var commenter_link = $("<a />", {href: "/" + $(mint_form).attr('data-rewards-recipient-address'), text: commenter_name});
                $(mint_form).find('.mint-msg').html("✨ " + commenter_link.prop('outerHTML') + " will get <a href='https://support.zora.co/en/articles/8192123-understanding-protocol-rewards-on-zora'>referral reward</a>. ");

            }

            // if(sales_status.enjoy_allowance_avail && sales_status.enjoy_allowance_avail > 0) {
            //     $(mint_form).find('.mint-msg').html( $(mint_form).find('.mint-msg').html() + "Tips avail: " + sales_status.enjoy_allowance_avail.toLocaleString('en-US') + " $enjoy");
            // }

            // if(parseInt($(mint_form).attr('data-collection-version')) > 5 && parseInt($(mint_form).attr('data-collection-version')) <= 12) {
            //     $(mint_form).find('.mint-recipient').hide();
            // }

            let qty = 1; // we can make this adjustable though O_O
            on_slider_change($(mint_form).find('.mint-slider')[0])

            $(mint_form).find('.mint-button').css({'opacity': '1.0'});

        /*} else if (sales_status.sale_open===true 
            && sales_status.sold_out===false 
            && sales_status.salesStrategy.currency) {

            $(mint_form).find('.mint-total .desc').text("")
            $(mint_form).find('.mint-slider-container').hide();
            $(mint_form).find('.mint-msg').html("Can't mint this on Token Chat. You might be able to <a href='" + zora_url + "'>mint on Zora ↗</a>");
            $(mint_form).find('.mint-button').text("Minting not available")
            $(mint_form).find('.mint-button').css({'opacity': '0.5'});
        */
        } else {
            $(mint_form).find('.mint-total .desc').text("")
            $(mint_form).find('.mint-slider-container').hide();
            $(mint_form).find('.mint-msg').html("Can't mint this on Token Chat. You might be able to <a href='" + zora_url + "'>mint on Zora ↗</a>");
            $(mint_form).find('.mint-button').text("Minting not available")
            $(mint_form).find('.mint-button').css({'opacity': '0.5'});
        }

    
    };

    window.on_slider_change = function(elt) {

        let qty = $(elt).val()
        let mint_form = $(elt).parents('.mint-form')[0]

        let progress
        if(qty==1) { progress = 0 }
        else if(qty==elt.max) { progress = 100 } 
        else { progress = (qty / elt.max * 100) - (10.75 - qty); }

        elt.style.background = `linear-gradient(to right, rgba(0,0,0,0) ${progress}%, var(--fg-color-10) ${progress}%)`;
        // elt.parentNode.style.filter = `saturate(${(qty / elt.max * 100)}%)`;

        mint_update_total_display(mint_form, qty)

    }


    window.on_recipient_change = function(elt) {

        console.log('on_recipient_change', elt, elt.value);

        // let elt = event.target;
        let mint_form = $(elt).parents('.mint-form')[0]

        // clear pending search for those fast typers
        if(ens_resolve_debounce != null) { window.clearTimeout(ens_resolve_debounce); }

        if(!elt.value) {
             $(mint_form).find('.recipient-addr').attr('data-recipient-addr', current_address)
             $(mint_form).find('.recipient-addr').text(current_address.replace(/^(......).+/,'$1'))
            return false;
        }

        if(isAddress(elt.value)) { 
            $(mint_form).find('.recipient-addr').text("✔");
            $(mint_form).find('.recipient-addr').attr('data-recipient-addr', elt.value)
            return false;
        }

        if(!elt.value.match(/\.(eth|xyz|art)$/)) { 
            $(mint_form).find('.recipient-addr').text("––––");
            $(mint_form).find('.recipient-addr').attr('data-recipient-addr', "")
            return false; 
        }

        ens_resolve_debounce = window.setTimeout(async function () { 

            ens_resolve_debounce = null;

            $(mint_form).find('.recipient-addr').text("Searching...")

            let address = await fetchEnsAddress({
                name: elt.value.trim(), chainId: 1
            })

            console.log("Got ens result", address)
            if(address) {
                 $(mint_form).find('.recipient-addr').attr('data-recipient-addr', address)
                 $(mint_form).find('.recipient-addr').text(address.replace(/^(......).+/,'$1'))
            } else {
                 $(mint_form).find('.recipient-addr').text("––––")
                 $(mint_form).find('.recipient-addr').attr('data-recipient-addr', "")
            }

        }, 200);

        return false;


    }

    window.mint_update_total_display = function (mint_form, qty) {

        $(mint_form).attr('data-qty', qty)

        let price_total, token_url;

        if($(mint_form).attr('data-collection-currency')) {
            price_total = BigInt(parseInt(qty)) * parseUnits($(mint_form).attr('data-price-per-token'))
      
            if($(mint_form).attr('data-collection-chain')=="zora") {
                token_url = "https://explorer.zora.energy/token/" + $(mint_form).attr('data-collection-currency')
            } else if($(mint_form).attr('data-collection-chain')=="base") {
                token_url = "https://basescan.org/token/" + $(mint_form).attr('data-collection-currency')
            } else if($(mint_form).attr('data-collection-chain')=="oeth") {
                token_url = "https://optimistic.etherscan.io/token/" + $(mint_form).attr('data-collection-currency')
            } else if($(mint_form).attr('data-collection-chain')=="blast") {
                token_url = "https://blastscan.io/token/" + $(mint_form).attr('data-collection-currency')
            } else if($(mint_form).attr('data-collection-chain')=="arb") {
                token_url = "https://arbiscan.org/token/" + $(mint_form).attr('data-collection-currency')
            } else if($(mint_form).attr('data-collection-chain')=="eth") {
                token_url = "https://etherscan.io/token/" + $(mint_form).attr('data-collection-currency')
            } else {
                token_url = "";
            }

            $(mint_form).attr('data-price-total', price_total)
            $(mint_form).find('.mint-total .desc').html(qty + "x NFT")
            $(mint_form).find('.mint-total .amount').html(formatEther(price_total) + " <a href='" + token_url + "'>$" + $(mint_form).attr('data-collection-currency-name').toUpperCase() + "</a>")

        } else {
            price_total = BigInt(parseInt(qty)) * (parseUnits($(mint_form).attr('data-price-per-token')) + parseUnits("777000000000000"))

            $(mint_form).attr('data-price-total', price_total)
            $(mint_form).find('.mint-total .desc').html(qty + "x NFT + " + qty + "x <a href='https://support.zora.co/en/articles/4981037-zora-mint-collect-fees'>Mint fee ↗</a>")
            $(mint_form).find('.mint-total .amount').text(formatEther(price_total) + " ETH")
        }

    }

    // $('.mint-button').on("click", async function(e) {
    window.mint_button_click = async function (e) {

        let elt = e.target
        let mint_form = $(elt).parents('.mint-form')[0];

        if($(elt).text() == "Minting not available") { return false; }

        let zora_url;
        if($(mint_form).attr('data-collection-chain')=="zgor") {
            zora_url = "https://testnet.zora.co/collect/" + $(mint_form).attr('data-collection-chain') + ":" + $(mint_form).attr('data-collection-address')
        } else {
            zora_url = "https://zora.co/collect/" + $(mint_form).attr('data-collection-chain') + ":" + $(mint_form).attr('data-collection-address')
        }

        if($(mint_form).attr('data-token-id')) {
            zora_url += "/" + $(mint_form).attr('data-token-id')
        }

        // if (!isAddress($(mint_form).find('.recipient-addr').attr('data-recipient-addr'))) {
        //     $(mint_form).find('.mint-msg').html("⚠️ NFT recipient seems incorrect.");
        //     return false;            
        // }

        if($(mint_form).find('textarea').val() == "") {
            $(mint_form).find('.mint-msg').html("ⓘ A comment is required to mint on Token Chat. <a href='" + zora_url + "'>Mint on Zora ↗</a>");
            return false;
        }


        let account = await getAccount(wagmiConfig)
        if(! account.isConnected) { web3modal.open(); return false;}
        console.log("account is ", account)


        if($(e).hasClass("disabled")) { return false; }
        $(e).addClass("disabled");


        let contract_chain_id
        contract_chain_id = CHAIN_IDS[$(mint_form).attr('data-collection-chain')]
        if (!contract_chain_id) { 
            console.log("Could not get chain id???"); 
            return false; 
        }

        let current_chain_id
        current_chain_id = account.chainId

        console.log("target chain is " + contract_chain_id + " current chain is " + current_chain_id + " contract version is " + $(mint_form).attr('data-collection-version'))

        if(current_chain_id != contract_chain_id) {

            try {
                
                const network = await switchChain(wagmiConfig, {
                    chainId: contract_chain_id,
                })

            } catch (e) {

                if(e.name == "ConnectorNotFoundError") { // shouldn't happen at this stage really
                    console.log("Wallet not connected")
                    $(mint_form).find('.mint-msg').text("⚠️ Lost connection to wallet. Reload this page, tap Connect, and try again.");
                } else if (e.name == "UserRejectedRequestError") { 
                    console.log("User declined")
                } else if (e.name == "SwitchChainError") { 
                    console.log("Mysterious chain switching error", e)
                    $(mint_form).find('.mint-msg').text("⚠️ Couldn't switch to the correct eth network (" + $(mint_form).attr('data-collection-chain') + "). It's possible your wallet does not support this.");
                } else {
                    console.log(e)
                    $(mint_form).find('.mint-msg').text("⚠️ Could not switch network: " + e.name);
                }

                $(e).removeClass("disabled");

            }

        }

        let rewards_address
        if($(mint_form).attr('data-rewards-recipient-address') 
            && $(mint_form).attr('data-rewards-recipient-address') != current_address) {
            rewards_address = $(mint_form).attr('data-rewards-recipient-address')
        } else {
            rewards_address = TOKENCHAT_REWARDS_ADDRESS
        }


        if($(mint_form).attr('data-collection-currency')) {


            let erc20_balance = await readContract(wagmiConfig, {
                address: $(mint_form).attr('data-collection-currency'),
                abi: abi_erc20,
                functionName: 'balanceOf',
                args: [ current_address ],
                chainId: contract_chain_id
            })
            if(erc20_balance < BigInt($(mint_form).attr('data-price-total'))) {
                $(mint_form).find('.mint-msg').text("⚠️ Get $" + $(mint_form).attr('data-collection-currency-name').toUpperCase() + " to mint this.");
                $(e).removeClass("disabled");
                return false;
            }


            let erc20_allowance = await readContract(wagmiConfig, {
                address: $(mint_form).attr('data-collection-currency'),
                abi: abi_erc20,
                functionName: 'allowance',
                args: [ current_address, $(mint_form).attr('data-token-minter') ],
                chainId: contract_chain_id
            })

            if(erc20_allowance < BigInt($(mint_form).attr('data-price-total'))) {

                console.log("You don't have enough allowance to mint, will need to approve")

                $(mint_form).find('.mint-msg').text("⏳ Requesting approval...");
    
                try {

                    let erc20_approval = await writeContract(wagmiConfig, {
                        address: $(mint_form).attr('data-collection-currency'),
                        abi: abi_erc20,
                        functionName: 'approve',
                        args: [ $(mint_form).attr('data-token-minter'), $(mint_form).attr('data-price-total') ],
                        chainId: contract_chain_id
                    })

                    $(mint_form).find('.mint-msg').text("⏳ Approving spend...");

                    const data = await waitForTransactionReceipt(wagmiConfig, {
                        chainId: contract_chain_id,
                        hash: erc20_approval,
                    })
                    console.log("wait for approval transaction completed: ",  data)

                } catch (e) {

                    if(e.name == "ConnectorNotFoundError") { // shouldn't happen at this stage really
                        console.log("Wallet not connected")
                        $(mint_form).find('.mint-msg').text("⚠️ Lost connection to wallet. Reload this page, tap Connect, and try again.");
                    } else if (e.name == "TransactionExecutionError") { 
                        $(mint_form).find('.mint-msg').text("⚠️ User cancelled.");
                        console.log(e)
                    } else if (e.name == "UserRejectedRequestError") { 
                        $(mint_form).find('.mint-msg').text("⚠️ User cancelled.");
                        console.log("User declined")
                        console.log(e)
                    } else if (e.name == "ContractFunctionExecutionError") {
                        $(mint_form).find('.mint-msg').html("⚠️ Unknown transaction error. Please report to <a href='https://twitter.com/fascinated'>@fascinated ↗</a>");
                        console.log(e)
                    } else {
                        $(mint_form).find('.mint-msg').text("⚠️ Could not approve spend: " + e.name);
                        console.log(e)
                    }

                    $(e).removeClass("disabled");

                }

            } else {
                console.log("Allowance of " + erc20_allowance + " matches/exceeds total " + $(mint_form).attr('data-price-total'))
            }

        }


        try {

            $(mint_form).find('.mint-msg').text("⏳ Submitting...");

            let tx_hash
            if ($(mint_form).attr('data-collection-currency')) {

                console.log("using abi_erc20_mint");

                tx_hash = await writeContract(wagmiConfig, {
                    address: $(mint_form).attr('data-token-minter'),
                    abi: abi_erc20_mint,
                    functionName: 'mint',
                    chainId: contract_chain_id,
                    args: [ 
                        current_address,
                        parseInt($(mint_form).attr('data-qty')), 
                        $(mint_form).attr('data-collection-address'),
                        parseInt($(mint_form).attr('data-token-id')),
                        parseInt($(mint_form).attr('data-price-total')),
                        $(mint_form).attr('data-collection-currency'),
                        rewards_address,
                        $(mint_form).find('textarea').val()
                    ]
                })

            } else if (parseInt($(mint_form).attr('data-collection-version')) >= 14) {

                console.log("using abi721_rewards");
                tx_hash = await writeContract(wagmiConfig, {
                    address: $(mint_form).attr('data-collection-address'),
                    abi: abi721_rewards,
                    functionName: 'mintWithRewards',
                    chainId: contract_chain_id,
                    args: [ 
                        account.address, 
                        parseInt($(mint_form).attr('data-qty')), 
                        $(mint_form).find('textarea').val(), 
                        rewards_address 
                    ],
                    value: $(mint_form).attr('data-price-total')
                })

            } else if (parseInt($(mint_form).attr('data-collection-version')) >= 12) {

                console.log("using abi721_norewards");
                tx_hash = await writeContract(wagmiConfig, {
                    address: $(mint_form).attr('data-collection-address'),
                    abi: abi721_norewards,
                    functionName: 'purchaseWithComment',
                    chainId: contract_chain_id,
                    args: [ 
                        parseInt($(mint_form).attr('data-qty')), 
                        $(mint_form).find('textarea').val()
                    ],
                    value: $(mint_form).attr('data-price-total')
                })

            } else if (parseFloat($(mint_form).attr('data-collection-version')) >= parseFloat(1.4)) {

                console.log("using abi1155_rewards");
                tx_hash = await writeContract(wagmiConfig, {
                    address: $(mint_form).attr('data-collection-address'),
                    abi: abi1155_rewards,
                    functionName: 'mintWithRewards',
                    chainId: contract_chain_id,
                    args: [ 
                        $(mint_form).attr('data-token-minter'),
                        parseInt($(mint_form).attr('data-token-id')), 
                        parseInt($(mint_form).attr('data-qty')), 
                        encodeAbiParameters(parseAbiParameters('address, string'), [account.address, $(mint_form).find('textarea').val()]),
                        rewards_address 
                    ],
                    value: $(mint_form).attr('data-price-total')
                })

            } else if (parseFloat($(mint_form).attr('data-collection-version')) >= parseFloat(1.2)) {

                console.log("using abi1155_norewards");
                tx_hash = await writeContract(wagmiConfig, {
                    address: $(mint_form).attr('data-collection-address'),
                    abi: abi1155_norewards,
                    functionName: 'mint',
                    chainId: contract_chain_id,
                    args: [ 
                        $(mint_form).attr('data-token-minter'),
                        parseInt($(mint_form).attr('data-token-id')), 
                        parseInt($(mint_form).attr('data-qty')), 
                        encodeAbiParameters(parseAbiParameters('address, string'), [account.address, $(mint_form).find('textarea').val()]),
                    ],
                    value: $(mint_form).attr('data-price-total')
                })
            
            } else {
                console.log("Unsupported contract version " + $(mint_form).attr('data-collection-version'))
            }


            $(mint_form).find('.mint-msg').text("⏳ Minting...");

            const data = await waitForTransactionReceipt(wagmiConfig, {
                chainId: contract_chain_id,
                hash: tx_hash,
            })
            console.log("wait for transaction completed: ",  data)


            // tell the server there is a new comment, and if the volume is not too high, it'll likely index it.
            // eventually this won't be needed if we are using websockets O_O
            let mint_notify = await $.ajax({
              type: "POST",
              url: '/api/new-comment/' + $(mint_form).attr('data-collection-chain') + ":" + tx_hash,
              data: { comment: $(mint_form).find('textarea').val() },
              dataType: 'json'
            });
            console.log("mint_notify: ", mint_notify);

            $(mint_form).find('.mint-msg').text("")
            $(mint_form).find('textarea').val("");
            $(mint_form).find('textarea').removeClass("active");

            // so we don't really know if the comment will be indexed by this point, but we can fake it
            let tx_link, commenter_display, commenter_url

            if($(mint_form).attr('data-collection-chain')=="zgor") { tx_link = "/comment/zgor:" + tx_hash } 
            else {tx_link = "/comment/" + tx_hash }

            if (current_ens) {
                commenter_display = current_ens.replace(/\.(eth|xyz|art)$/, '')
                commenter_url = "/" + current_ens
            } else {
                commenter_display = current_address.replace(/^(......).+/,'$1');
                commenter_url = "/" + current_address
            }

            gtag('event', 'mint', {'event_value': parseInt($(mint_form).attr('data-qty'))});

            if($(mint_form).parents('#collection-tooltip').length) {
                // if(page_data.meta.page_name!="user") {
                //     $('#comments .clock').after('<div class="grid-collection"><div class="txn"><a class="txn" href="' + tx_link + '">just now</a></div><div class="commenter"><a class="freq" href="' + commenter_url + '">' + commenter_display + '</a></div><div class="comment-text">' + mint_notify.result.comment + '</div></div>');
                // }
                hide_collection_info();
            } else {
                $(mint_form).after('<div class="grid-collection"><div class="txn"><a class="txn" href="' + tx_link + '">just now</a></div><div class="commenter"><a class="freq" href="' + commenter_url + '">' + commenter_display + '</a></div><div class="comment-text">' + mint_notify.result.comment + '</div></div>');            
            }

            // show_toast("<span style='margin-right:10px;'>✅</span>Comment minted on " + );

            increment_main_comment_count(parseInt($(mint_form).attr('data-qty')))

        } catch (e) {

            const isInsufficientFundsError = (e instanceof InsufficientFundsError)

            if(e.name == "ConnectorNotFoundError") { // shouldn't happen at this stage really
                console.log("Wallet not connected")
                $(mint_form).find('.mint-msg').text("⚠️ Lost connection to wallet. Reload this page, tap Connect, and try again.");
            } else if (e.name == "TransactionExecutionError") { 
                $(mint_form).find('.mint-msg').text("⚠️ Minting cancelled.");
                console.log("User declined")
                console.log(e)
            } else if (e.name == "UserRejectedRequestError") { 
                console.log("User declined")
                console.log(e)
            } else if (e.name == "InsufficientFundsError" || isInsufficientFundsError) {
                if ($(mint_form).attr('data-collection-chain')=="zora") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://bridge.zora.energy/'>Bridge some ETH to Zora</a>.");
                } else if ($(mint_form).attr('data-collection-chain')=="base") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://bridge.base.org/deposit'>Bridge some ETH to Base</a>.");
                } else if ($(mint_form).attr('data-collection-chain')=="zgor") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://testnet.zora.co/'>Bridge some ETH to Zora Goerli Testnet</a>.");
                } else if ($(mint_form).attr('data-collection-chain')=="oeth") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://app.optimism.io/bridge/deposit'>Bridge some ETH to Optimism</a>.");
                } else if ($(mint_form).attr('data-collection-chain')=="pgn") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://bridge.publicgoods.network/'>Bridge some ETH to PGN</a>.");
                } else if ($(mint_form).attr('data-collection-chain')=="arb") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://bridge.arbitrum.io/?destinationChain=arbitrum-one&sourceChain=ethereum'>Bridge some ETH to Arbitrum</a>.");
                } else {
                    $(mint_form).find('.mint-msg').text("⚠️ Insufficient funds.");
                }
            } else if (e.name == "ContractFunctionExecutionError") {
                $(mint_form).find('.mint-msg').html("⚠️ Can't mint this on Token Chat. You might be able to <a href='" + zora_url + "'>mint on Zora ↗</a>");
                console.log(e)
            } else {
                $(mint_form).find('.mint-msg').text("⚠️ Could not mint comment: " + e.name);
                console.log(e)
            }

            $(e).removeClass("disabled");

        }

        $(e).removeClass("disabled");

    };

    window.mint_cancel_click = function (e) {

        let elt = e.target
        let mint_form = elt.parentNode.parentNode;

        $(mint_form).find('textarea').removeClass("active");
        $(mint_form).find('textarea').val("");

    };

